import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import CallCount from "../components/call-count";
import PageLayout from "../components/page-layout";
import HowItWorksSection from "../components/sections/how-it-works";
import TeamSection from "../components/sections/team";
import Testimonials from "../components/testimonials";

import smiley from "../images/upside-down-face.png";

import * as style from "./index.module.css";
import * as specialStyle from "./mentors.module.css";

import "swiper/css";
import "swiper/css/navigation";

const MentorsPage = () => {
  return (
    <PageLayout
      title="Call A Colleague - Mentors"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={smiley}
    >
      <section id="above-fold" className={style.aboveFold}>
        <StaticImage
          src="../images/upside-down-face.png"
          width={50}
          height={50}
          alt="smiling face"
        />
        <h1>Call A Colleague</h1>

        <div className={style.callCountDisplay}>
          <div>
            <CallCount />
          </div>
          <div>calls booked</div>
        </div>

        <p>
          Peer to Peer Career Mentoring for Ukrainian professionals forced to
          flee from Russian war 🙈
        </p>

        <h3 className={specialStyle.h3}>Is it for you?</h3>

        <ul>
          <li>You are a busy professional?</li>
          <li>You are unable to spend days helping Ukrainians?</li>
          <li>Helplessness doesn't let you work properly?</li>
        </ul>

        <h3 className={specialStyle.h3}>
          Register as a career mentor for a colleague forced to flee from
          Putin's war!
        </h3>

        <ul>
          <li>
            20 Minutes video calls via{" "}
            <a href="https://meet.jit.si/" target="_blank">
              jitsi
            </a>
          </li>
          <li>When and where you want</li>
          <li>No strings attached</li>
        </ul>

        <br />
        <br />
        <p>
          <a href="https://forms.gle/TgLwbh6UmGapzghZA" className="cac-button">
            Become a mentor
            <span style={{ fontSize: "0.8rem" }}>(2 minutes)</span>
          </a>
        </p>
      </section>

      <HowItWorksSection
        title="How it works?"
        items={[
          {
            title: "Step 1",
            text: "Fill out the registration form and tell us when you can help.",
          },
          {
            title: "Step 2",
            text: "Get notified about call requests by email.",
          },
          {
            title: "Step 3",
            text: "Confirm or decline the calls via buttons in the email.",
          },
          {
            title: "Step 4",
            text: "Use the call confirmation card in the email to cancel if needed.",
          },
          {
            title: "Step 5",
            text: "Communicate with mentees further via email expanding your network of colleagues.",
          },
          {
            title: "Keep in mind",
            text: (
              <>
                We prepped the necessary career resources to{" "}
                <a href="/resources" target="_blank">
                  help you help
                </a>{" "}
                (CV course, samples, job boards, etc.).
              </>
            ),
          },
        ]}
      />

      <section id="testimonials" className={style.testimonialSection}>
        <h2>We helped</h2>
        <Testimonials />
      </section>

      <TeamSection />

      <section className={style.embedSection}>
        <h2>More career advice</h2>

        <div
          dangerouslySetInnerHTML={{
            __html:
              '<!-- LightWidget WIDGET --><script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="//lightwidget.com/widgets/0ad6ba9088a25f31868d3132c551fa7a.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>',
          }}
        />

        <p>
          <a href="https://forms.gle/TgLwbh6UmGapzghZA" className="cac-button">
            Become a mentor
            <span style={{ fontSize: "0.8rem" }}>(2 minutes)</span>
          </a>
        </p>
      </section>
    </PageLayout>
  );
};

export default MentorsPage;
